export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/pending',
    name: 'pending-users',
    component: () => import('@/views/Users/Pending.vue'),
    meta: {
      pageTitle: 'Pending Users',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Pending Users',
          active: true,
        }
      ],
    },
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('@/views/Users/User.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'User',
          active: true,
        },
      ],
    },
  },
]
