import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";

// Routes
import auth from "./routes/auth";
import users from "./routes/users";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		...auth,
		...users,
		{
			path: "/",
			name: "dashboard",
			component: () => import("@/views/Dashboard/Dashboard.vue"),
			meta: {
				pageTitle: "Dashboard",
				breadcrumb: [
					{
						text: "Dashboard",
						active: true,
					},
				],
			},
		},
		{
			path: "/analytics",
			name: "analytics",
			component: () => import("@/views/Analytics/Analytics.vue"),
			meta: {
				pageTitle: "Analytics",
				breadcrumb: [
					{
						text: "Analytics",
						active: true,
					},
				],
			},
		},
		{
			path: "/settings",
			name: "settings",
			component: () => import("@/views/Settings/Index.vue"),
			meta: {
				pageTitle: "Settings",
				breadcrumb: [
					{
						text: "Settings",
						active: true,
					},
				],
			},
		},
		{
			path: "/settings/auth",
			name: "settings.auth",
			component: () => import("@/views/Settings/Auth.vue"),
			meta: {
				pageTitle: "Authentication Page",
				breadcrumb: [
					{
						text: "Settings",
						to: "/settings",
					},
					{
						text: "Authentication Page",
						active: true,
					},
				],
			},
		},
		{
			path: "/settings/announcements",
			name: "settings.announcements",
			component: () => import("@/views/Settings/Announcements/Index.vue"),
			meta: {
				pageTitle: "Manage Announcements",
				breadcrumb: [
					{
						text: "Settings",
						to: "/settings",
					},
					{
						text: "Manage Announcements",
						active: true,
					},
				],
			},
		},
		{
			path: "/error-404",
			name: "error-404",
			component: () => import("@/views/error/Error404.vue"),
			meta: {
				layout: "full",
			},
		},
		{
			path: "*",
			redirect: "error-404",
		},
	],
});

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn();
	if (to.meta.resource === "Auth") {
		if (isLoggedIn) {
			return next("home");
		}
	} else {
		if (!isLoggedIn) {
			return next("login");
		}
	}
	return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
