import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

Vue.component(FeatherIcon.name, FeatherIcon);

//Setting Currency
Vue.prototype.$currency = (code) => {
	let symbol = "$";
	if (code == "EUR") {
		symbol = "€";
	} else if (code == "JPY") {
		symbol = "¥";
	} else if (code == "INR") {
		symbol = "₹";
	} else if (code == "GBP") {
		symbol = "£";
	} else if (code == "CHF") {
		symbol = "CHF";
	} else if (code == "SEK") {
		symbol = "Kr";
	} else if (code == "MYR") {
		symbol = "RM";
	} else if (code == "MXN") {
		symbol = "₱";
	} else if (code == "PLN") {
		symbol = "zł";
	} else if (code == "DKK" || code == "NOK") {
		symbol = "Kr";
	}
	return symbol;
};

//Setting Plans
Vue.prototype.$plans = () => {
	let plans = {};
	plans[process.env.VUE_APP_PLUS_PLAN] = "Plus Monthly";
	plans[process.env.VUE_APP_PLUS_PLAN_YEARLY] = "Plus Yearly";
	plans[process.env.VUE_APP_PLUS_PLAN_IND] = "Plus Monthly (INR)";
	plans[process.env.VUE_APP_PLUS_PLAN_YEARLY_IND] = "Plus Yearly (INR)";
	return plans;
};
